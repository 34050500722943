import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import { HomePage } from "./pages/home/HomePage";
import { PhotoPage } from "./pages/photo/PhotoPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/photo/*" element={<PhotoPage />} />
        <Route path="*" element={<HomePage />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
