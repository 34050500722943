import React from "react";
import { Card, Form, Input, Select, Flex, DatePicker, Button } from "antd";
import { v4 as uuidv4 } from "uuid";
import { Dayjs } from "dayjs";

const FilmTypes = ["Ilford HP+"];
const { Meta } = Card;

export type Photo = {
  id: string;
  title: string;
  description: string;
  src: string;
};
const photos: Photo[] = [
  {
    id: uuidv4(),
    title: "OG",
    description: "",
    src: "/images/example/OG.png",
  },
  {
    id: uuidv4(),
    title: "Scan",
    description: "",
    src: "/images/example/Scan.png",
  },
  {
    id: uuidv4(),
    title: "Edit",
    description: "",
    src: "/images/example/Edit.png",
  },
];

export type PhotoForm = {
  name: string;
  date: Dayjs;
  film: string;
};
export const MyCard = ({
  title,
  description,
  src,
}: {
  title: string;
  description: string;
  src: string;
}) => (
  <Card style={{ width: 240 }} cover={<img alt="example" src={src} />}>
    <Meta title={title} description={description} />
  </Card>
);

export const PhotoPage = () => {
  const onSubmit = (formDate: PhotoForm) => {
    console.log({ formDate });
    console.log({ date: formDate.date.format("MM-DD-YYYY") });
  };

  return (
    <div>
      <Flex vertical={false} gap="1rem" align="center" justify="center">
        {photos.map((photo) => (
          <MyCard
            key={photo.id}
            title={photo.title}
            description={photo.description}
            src={photo.src}
          />
        ))}
      </Flex>

      <Form style={{ margin: "3rem" }} onFinish={onSubmit}>
        <Flex gap={2}>
          <Flex>
            <Form.Item label="name" name="name" required={true}>
              <Input id="name" />
            </Form.Item>
          </Flex>
          <Flex>
            <Form.Item
              label="Date"
              name="date"
              required={true}
              valuePropName="dateString"
            >
              <DatePicker />
            </Form.Item>
          </Flex>
          <Flex>
            <Form.Item label="Selct Film" name="film" required={true}>
              <Select style={{ width: 120 }}>
                {FilmTypes.map((name) => (
                  <Select.Option value={name}>{name}</Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Flex>
        </Flex>
        <Button htmlType="submit">Submit</Button>
      </Form>
    </div>
  );
};
