import "./home.scss"

export const HomePage = () => {
    return (
        <div>
            <header className="home header">
                <h2>Photo Alpum</h2>
                <div></div>
            </header>
            <div className="home body">
                <img className="home image" src="https://nextui-docs-v2.vercel.app/images/fruit-1.jpeg" alt=""/>
                <img className="home image"
                     src="https://app.requestly.io/delay/1000/https://nextui-docs-v2.vercel.app/images/fruit-4.jpeg" alt=""/>
                <img className="home image" src="https://nextui-docs-v2.vercel.app/images/fruit-1.jpeg" alt=""/>
                <img className="home image"
                     src="https://app.requestly.io/delay/1000/https://nextui-docs-v2.vercel.app/images/fruit-4.jpeg" alt=""/>
            </div>
        </div>)
}